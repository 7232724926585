// Gatsby imports
import React from "react";
import { graphql } from "gatsby";

// Plugin imports
import { GatsbyImage as Image, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { useIntl, Link } from "gatsby-plugin-react-intl";

// Custom components
import Seo from "../components/SEO";
import Layout from "../components/Layout";
import SplashTitle from "../components/SplashTitle";

// Utilities
import { getResolvedVersionForLanguage } from "../utilities/Node";
import LangNotAvailableNotification from "../components/LangNotAvailableNotification";

export default function BlogPost({ data }) {
  const shortcodes = { Image, Link };

  const intl = useIntl();

  const {
    frontmatter: { author, date, title, featureImageAlt, featureImage, ogImage },
    fields: { slug, lang },
    excerpt,
    body,
  } = getResolvedVersionForLanguage(data, intl);

  const ogData = {
    ogDescription: excerpt,
    ogTitle: title,
    ogUrl: `https://www.ubicube.eu${slug}`,
    ogImage: `https://www.ubicube.eu${ogImage.childImageSharp.resize.src}`,
    ogImageHeight: ogImage.childImageSharp.resize.height,
    ogImageWidth: ogImage.childImageSharp.resize.width,
  };

  return (
    <Layout intl={intl} title="Blog">
      <Seo title={title} ogData={ogData} />
      <SplashTitle
        image={getImage(data.file.childImageSharp)}
        subheadline={intl.formatMessage(
          { id: "blog-post.subheadline", defaultMessage: "Our <mark>Blog</mark>" },
          { mark: (text) => <mark>{text}</mark> }
        )}
      >
        {title}
      </SplashTitle>
      <main>
        {lang !== intl.locale && <LangNotAvailableNotification intl={intl} />}
        <section className="p-6 my-8 uc-blog-article">
          <div className="container mx-auto max-w-screen-md mb-16">
            <h2 className="font-bold mb-2">{title}</h2>
            <p className="my-2">
              {intl.formatDate(date)} {intl.formatMessage({ id: "written-by", defaultMessage: "written by" })}{" "}
              <span className="font-bold">{author}</span>
            </p>
            <div className="mt-10 prose xl:prose-lg">
              <MDXProvider components={shortcodes}>
                <MDXRenderer image={getImage(featureImage)} imageAlt={featureImageAlt}>
                  {body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($slug: String!) {
    allMdx(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          body
          id
          excerpt
          fields {
            lang
            slug
          }
          frontmatter {
            author
            date
            title
            featureImageAlt
            featureImage: featureImage {
              childImageSharp {
                gatsbyImageData(width: 500, formats: AUTO)
              }
            }
            ogImage: featureImage {
              childImageSharp {
                resize(width: 1200, height: 630, pngQuality: 50, toFormat: JPG, cropFocus: ENTROPY) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "namib-desert.jpg" }) {
      ...SplashImage
    }
  }
`;
